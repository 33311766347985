import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';
import { Login, OurStudents, Scheme } from './components'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProtectedRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem('token')

  return token ? (
    element
  ) : (
    <Navigate to='/institute/login' />
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
      <ToastContainer />
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Scheme />} />
            <Route path='/international/our-students' element={<ProtectedRoute element={<OurStudents />} />} />
          </Route>
            <Route path='/institute/login' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
)

reportWebVitals();
