import React, { useState } from 'react'
import { Group, AccountTree, Home, Search } from '@mui/icons-material'
import logo from '../../assets/logo.jpg'
import './Header.css'
import SecondaryNavbar from './SecondaryNavbar'

const Header = () => {

  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = [
    null,
    'January',
    'February',
    'March ',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const [searchedQuery, setSearchedQuery] = useState('')

  function getTehranDate() {
    const date = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Tehran" }))
    const month = months[date.getMonth() + 1]
    const day = date.getDate()
    const year = date.getFullYear()
    const hours = date.getHours().toString()
    const minutes = date.getMinutes().toString()
    const time = `${hours.length === 1 ? `0${hours}` : `${hours}`}:${minutes.length === 1 ? `0${minutes}` : `${minutes}`}`
    const weekday = weekdays[date.getDay()]

    return `${weekday}, ${month} ${day}, ${year} Current Time: ${time} in Tehran`
  }

  const handleSearchByKey = event => {
    if (event.key === 'Enter') {
      window.location = `https://gsia.sums.ac.ir/en/srch?${searchedQuery}`
    }
  }
  
  return (
    <>
      <nav className='flex bg-[#1A3C6A] app__header px-[140px]'>
        <div className='cursor-pointer' onClick={() => window.location = 'https://gsia.sums.ac.ir/en'}>
          <img className='h-[110px]' src={logo} alt="SHIRAZ UNIVERSITY OF MEDICAL SCIENCES" />
        </div>

        <div className='flex items-end text-white mb-2 text-[8pt]'>
          <p>{getTehranDate()}</p>
        </div>

        <div className='content-center relative'>
          <ul className='flex'>
            <li><a href="https://www.sums.ac.ir/" target='blank'>&nbsp;&nbsp;فا&nbsp;&nbsp;</a></li>
            <li className='tooltip users__tooltip relative'><a href="https://gsia.sums.ac.ir/en/login"><Group /></a></li>
            <li className='tooltip sitemap__tooltip relative'><a href="https://gsia.sums.ac.ir/en/sitemap"><AccountTree /></a></li>
            <li className='tooltip home__tooltip relative'><a href="https://gsia.sums.ac.ir/en"><Home /></a></li>
            <li className='!bg-[#e36b2c]'><a href='temp.com'><label htmlFor='search-bar' className='cursor-pointer'><Search /></label></a></li>
          </ul>

          <p className='absolute bottom-0 right-1'>
            <input
              id="search-bar"
              className='rounded border-[5px] border-[#e36b2c] px-[1rem] py-[1px] text-[14px] mb-2 outline-none'
              placeholder='Search key...'
              type="search"
              value={searchedQuery}
              onChange={event => setSearchedQuery(event.target.value)}
              onKeyDown={event => handleSearchByKey(event)}
            />
          </p>

        </div>
      </nav>
      <SecondaryNavbar />
    </>
  )
}

export default Header
