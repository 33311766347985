import React, { useEffect, useState } from 'react'
import { ArrowDropDown, DownloadRounded, ReceiptLongRounded, SchoolOutlined } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import profileImage from '../../assets/default-profile.jpg'
import './OurStudents.css'
import { Field, Form, Formik } from 'formik';
import { searchStudentSchema } from './validationSchema';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import researchPaper1 from '../../assets/Exploring the Pharmacological Mechanisms of Addiction and Novel Therapeutic Approaches.pdf'
import researchPaper2 from '../../assets/Impact of Antibiotic Stewardship Programs on Antibiotic Resistance Patterns in Hospital Settings.pdf'
import researchPaper3 from '../../assets/Investigating the Role of Nanotechnology in Drug Delivery Systems for Enhanced Therapeutic Outcomes.pdf'
import researchPaper4 from '../../assets/Pharmacoeconomics.pdf'
import researchPaper5 from '../../assets/Pharmacokinetic Considerations in Pediatric Drug Therapy.pdf'
import researchPaper6 from '../../assets/Pharmacovigilance.pdf'
import researchPaper7 from '../../assets/The Influence of Gut Microbiota on Drug Metabolism and Pharmacodynamics.pdf'
import researchPaper8 from '../../assets/The Role of Pharmacogenomics in Personalized Medicine.pdf'
import researchPaper9 from '../../assets/Evaluating the Efficacy and Safety of Herbal Medicines in Treating Common Ailments.pdf'
import researchPaper10 from '../../assets/Assessment of Drug.pdf'

const OurStudents = () => {

  const [searchResult, setSearchResult] = useState(false)
  const [academicYear, setAcademicYear] = useState(2024)
  const [studentName, setStudentName] = useState('')
  const [studentId, setStudentId] = useState('')
  const [subjects, setSubjects] = useState([])
  const [loader, setLoader] = useState(false)

  const handleStudentSearch = (studentValues) => {

    setLoader(true)
    axios.post(`https://smu-international.vercel.app/api/itp/students/verify`, studentValues)
      .then(res => {
        if (res.data.success) {
          setSearchResult(true)
          setStudentId(res.data.studentId)
          fetchSubjectsOfStudent(res.data.studentId)
        }
        setLoader(false)
      })
      .catch(err => {
        console.log('Failed to fetch student details. Error:', err)
        toast.error(err.response.data.message)
        setLoader(false)
      })
  }

  const fetchSubjectsOfStudent = async studentId => {
    try {
      setLoader(true)
      const subjects = await axios.get(`https://smu-international.vercel.app/api/subjects/${academicYear}/student/${studentId}`)

      if (subjects) {
        setSubjects(subjects?.data?.subjects)
      }
      setLoader(false)

    } catch (error) {
      console.log('Cannot fetch subjects of the students due to error', error)
      setLoader(false)
    }
  }

  const fetchStudentName = studentNumber => {
    setLoader(true)
    axios.get(`https://smu-international.vercel.app/api/itp/students/${studentNumber}/name`)
      .then(res => {
        res.status === 200 && setStudentName(res.data.name)
        setLoader(false)
      })
      .catch(err => {
        console.log('Failed to fetch student name. Error:', err)
        setLoader(false)
      })
  }

  const initialStudentSearchFormValues = {
    studentNumber: '',
    passkey: '',
  }

  const handleResearchPapersDownload = () => {
    const fileUrls = [
      { url: researchPaper1, name: 'Exploring the Pharmacological Mechanisms of Addiction and Novel Therapeutic Approaches' },
      { url: researchPaper2, name: 'Impact of Antibiotic Stewardship Programs on Antibiotic Resistance Patterns in Hospital Settings' },
      { url: researchPaper3, name: 'Investigating the Role of Nanotechnology in Drug Delivery Systems for Enhanced Therapeutic Outcomes' },
      { url: researchPaper4, name: 'Pharmacoeconomics' },
      { url: researchPaper5, name: 'Pharmacokinetic Considerations in Pediatric Drug Therapy' },
      { url: researchPaper6, name: 'Pharmacovigilance' },
      { url: researchPaper7, name: 'The Influence of Gut Microbiota on Drug Metabolism and Pharmacodynamics' },
      { url: researchPaper8, name: 'The Role of Pharmacogenomics in Personalized Medicine' },
      { url: researchPaper9, name: 'Evaluating the Efficacy and Safety of Herbal Medicines in Treating Common Ailments' },
      { url: researchPaper10, name: 'Assessment of Drug' },
    ]

    fileUrls.forEach(fileUrl => {
      const link = document.createElement('a')
      link.href = fileUrl.url
      link.setAttribute('download', fileUrl.name)
      document.body.appendChild(link)
      link.click()
      link.style.display = 'none'
    })
  }

  useEffect(() => {
    fetchSubjectsOfStudent(studentId)
  }, [academicYear, studentId])

  return (
    <div className='our-students'>

      {loader && <Loader />}

      <Formik
        initialValues={initialStudentSearchFormValues}
        validationSchema={searchStudentSchema}
        onSubmit={(values) => {
          handleStudentSearch(values)
        }}
      >
        {
          ({ errors, touched, handleChange }) => (
            <Form>
              <Box p={2}>
                <Typography variant="h5" gutterBottom className='!font-[Verdana] text-[1rem] text-[#09347a] !font-[700] mb-2 uppercase border-b-[2px] border-dotted pb-3'>
                  Student Search
                </Typography>
                <Field
                  fullWidth
                  type='number'
                  as={TextField}
                  sx={{ mb: 2 }}
                  name='studentNumber'
                  label="Student Number"
                  onChange={event => {
                    handleChange(event)
                    if (event.target.value.length === 10) {
                      fetchStudentName(event.target.value)
                    }
                  }}
                  error={touched.studentNumber && !!errors.studentNumber}
                  helperText={touched.studentNumber && errors.studentNumber}
                />
                <Field
                  disabled
                  fullWidth
                  as={TextField}
                  sx={{ mb: 2 }}
                  label="Student Name"
                  value={studentName}
                />
                <Field
                  fullWidth
                  type='number'
                  as={TextField}
                  sx={{ mb: 2 }}
                  name='passkey'
                  label="Passkey"
                  onChange={handleChange}
                  error={touched.passkey && !!errors.passkey}
                  helperText={touched.passkey && errors.passkey}
                />
                <Button type='submit' variant="contained" sx={{ mb: 2 }}>
                  Search
                </Button>

                {searchResult && (
                  <Box mt={2}>
                    <Typography variant="h6">Search Result:</Typography>
                    <Typography>{searchResult}</Typography>
                  </Box>
                )}
              </Box>
            </Form>
          )
        }

      </Formik>

      {
        searchResult &&
        <div className='bg-[#a0b0b2] rounded-[5px] p-6 mt-4 mb-8'>
          <div className='flex items-center justify-between'>
            <p className='font-[600] text-[26px] tracking-[0.8px]'>Dashboard</p>
            <div className='flex'>
              <p>Academic year:</p>
              <div className='flex items-center our-students__tag !my-0 !ml-2 !bg-[#000000]'>
                <select className='!bg-[#000000] cursor-pointer outline-none' value={academicYear} onChange={event => setAcademicYear(parseInt(event.target.value))}>
                  <option value={2024}>2024 <ArrowDropDown /></option>
                  <option value={2023}>2023</option>
                </select>
              </div>
            </div>
          </div>

          <div className='mt-4 flex flex-col md:flex-row gap-x-8'>
            <div className='bg-[#84a98c] rounded-[10px] py-4 px-8 flex items-center gap-x-4 w-max'>
              <div className='w-[100px] h-[100px] rounded-[99px] overflow-hidden'>
                <img className='w-full h-full' src={profileImage} alt='Profile' />
              </div>

              <div>
                <p className='font-[600]'>Melika Abdolkarim Panahi</p>
                <p>Student ID: 1102220258</p>
                <p>Passport No: A54088202</p>
              </div>
            </div>

            <div className='rounded-[10px] py-4 px-8 bg-[#cad2c5]'>
              <p className='font-[600]'>
                <SchoolOutlined />
                &nbsp;
                Academic details:
              </p>

              <p className='our-students__tag'>{`Doctor of Pharmacy (Pharm D. ${academicYear - 1} - ${academicYear})`}</p>
              <div className='flex gap-x-4'>
                <p className='our-students__tag'>MIT - WPU, Pune, India</p>
                <p className='our-students__tag'>{`${academicYear === 2024 ? '2nd' : '1st'} year`}</p>
              </div>
            </div>

            <div className="rounded-[10px] py-4 px-8 bg-[#cad2c5]">
              <p className='font-[600] mb-4'>
                <ReceiptLongRounded />
                &nbsp;
                Research Papers
              </p>

              <button
                onClick={handleResearchPapersDownload}
                className="research-papers__button flex items-center bg-white box-border font-sans border-0 rounded-[0.5rem] text-[#111827] text-[0.875rem] font-[600] leading-5 py-3 px-4 text-center cursor-pointer hover:bg-[#F9FAFB]" role="button">
                <span className='bg-black rounded-full content-center w-[30px] h-[30px]'>
                  <DownloadRounded sx={{ color: 'white'}} />
                </span>
                &nbsp;
                <span>Download all</span>
              </button>
            </div>
          </div>

          <div className='mt-4 flex flex-col md:flex-row gap-x-8'>
            <div className='bg-[#cad2c5] py-4 px-8 rounded-[10px]'>
              <p className='font-[600]'>Internal marks details</p>

              <table className='mt-4 our-students__score'>
                <tr>
                  <th>No.</th>
                  <th>Subject</th>
                  <th className='flex flex-col items-center justify-center pr-0'>
                    <tr>
                      <th>Marks obtained</th>
                    </tr>
                    <tr>
                      <th>Theory</th>
                      <th>Practicals</th>
                    </tr>

                  </th>
                  <th>Credits</th>
                </tr>

                {
                  subjects && subjects.map((subject, index) =>
                    <tr key={subject.id}>
                      <td>{`${index < 9 ? '0' : ''}${index + 1}`}</td>
                      {/* <td className='!text-left'>{academicYear === 2024 ? 'Pathophysiology' : 'Human Anatomy and Physiology'}</td> */}
                      <td className='!text-left'>{subject?.name}</td>
                      <td>
                        <tr className='flex'>
                          <td className='block w-[50%]'>{subject?.theoryMarks}</td>
                          <td className='block w-[50%]'>{subject?.practicalMarks}</td>
                        </tr>
                      </td>
                      <td>{subject?.credits}</td>
                    </tr>
                  )
                }

              </table>
            </div>

            <div className='bg-[#cad2c5] py-4 px-8 rounded-[10px] our-students__attendance'>
              <p className='font-[600] mb-2'>Attendance <span className='font-[400]'>(In %)</span></p>
              <p className='text-[14px]'>Theory: <span className='w-[20px] inline-block h-[10px] bg-[#006a00]'></span></p>
              <p className='text-[14px] mb-2'>Practicals: <span className='w-[20px] inline-block h-[10px] bg-[#000088]'></span></p>
              {
                subjects && subjects.map(subject =>
                  <div key={subject.id}>
                    <p className='min-w-[30px]'>{subject?.shortName}</p>
                    <p className='w-[90px] relative overflow-hidden'>
                      <span
                        className="absolute inset-0 bg-[#006a00] h-[4px]"
                        style={{ width: `${subject?.attendance}%` }}
                      ></span>
                      <span
                        className="absolute inset-0 bg-[#000088] h-[4px] top-[4px]"
                        style={{ width: `${subject?.attendance - 10}%`, zIndex: 0 }}
                      ></span>
                    </p>
                    <p>{subject?.attendance}</p>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default OurStudents


