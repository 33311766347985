import React from 'react'
import './Footer.css'
import linkedInIcon from '../../assets/linkedin.png'

const Footer = () => {
  return (
    <footer className='footer text-white relative text-[11pt]'>

      <p className='absolute -top-[18px] left-[50%] translate-x-[-50%] cursor-pointer tooltip linkedin__tooltip'>
          <img
            className=''
            width={36}
            src={linkedInIcon} 
            alt="Linkedin" 
            onClick={() => window.open('https://www.linkedin.com/school/shiraz-university-of-medical-sciences/', '_blank')} 
          />
      </p>

      <div className='bg-[#413d3c] py-[40px] px-[240px] flex flex-col md:flex-row'>
        <div>
          <p>Announcements</p>
          <ul>
            <li><a href='http://gsia.sums.ac.ir/en/page/18480/Quality-Control-of-Herbal-Medicine-and-Natural-Products--Instrumental-Based-'>Quality Control of Herbal Medicine and Natural Products (Instrumental Based)</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18477/ICO-Fellowship-Announcement'>ICO Fellowship Announcement</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18316/SUMS-Visiting-Medical-Electives'>Medical Electives at SUMS</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18466/SUMS-Announcement-for-M-Sc--of-Medical-Journalism'>Medical Journalism Admission Announcement at SUMS</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18407/SUMS-Graduate-Announcement-for-Basic-Sciences-Ph--D--Program'>Ph.D. Programs</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18406/SUMS-Announcement-for-Specialty--Subspecialty-and-Fellowship-Programs'>Specialty, Subspecialty and Fellowship Programs</a></li>
          </ul>
        </div>

        <div>
          <p>Schools</p>
          <ul>
            <li><a href='http://gsia.sums.ac.ir/en/page/18847/School-of-Medicine'>School of Medicine</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18848/School-of-Dentistry'>School of Dentistry</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/contact/18382/SUMS-School-of-Pharmacy'>School of Pharmacy</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18842/School-of-Paramedical-Sciences'>School of Paramedical Sciences</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18844/School-of-Nursing-and-Midwifery'>School Of Nursing and Midwifery</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18843/School-of-Rehabilitation-Sciences'>School of Rehabilitation Sciences</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/contact/18387/School-of-Advanced-Medical-Sciences-and-Technologies'>School of Advanced Medical Sciences and Technologies</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18841/School-of-Health-Management-and-Information-Sciences'>School of Health</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18841/School-of-Health-Management-and-Information-Sciences'>School of Health Management and Information Sciences</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18845/School-of-Nutrition-and-Food-Sciences'>School of Nutrition and Food sciences</a></li>
          </ul>
        </div>

        <div>
          <p>Research Centers</p>
          <ul>
            <li><a href='http://gsia.sums.ac.ir/en/page/18655/Clinical-Microbiology-Research-Center'>Clinical Microbiology Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18656/Shiraz-Institute-for-Cancer-Research'>Shiraz Institute for Cancer Research</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18658/Medicinal-and-Natural-Products-Chemistry-Research-Center'>Medicinal and Natural Products Chemistry Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18657/Shiraz-Transplant-Research-Center'>Shiraz Transplant Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18659/Health-Policy-Research-Center'>Health Policy Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18660/Shiraz-Burn-Research-Center'>Shiraz Burn Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18662/Nanomedicine-and-Nanobiology-Research-Center'>Nanomedicine and Nanobiology Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18661/Hematology-Research-Center'>Hematology Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18663/Gastroenterohepatology-Research-Center'>Gastroenterohepatology Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18664/Nanotechnology-in-Drug-Delivery-Research-Center'>Nanotechnology in Drug Delivery Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18667/Non-communicable-Diseases-Research-Center'>Non-communicable Diseases Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18668/Nutrition-Research-Center'>Nutrition Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18669/Rehabilitation-Sciences-Research-Center'>Rehabilitation Sciences Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18665/Clinical-Neurology-Research-Center'>Clinical Neurology Research Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18439/SUMS-Research-Centers'>More</a></li>
          </ul>
        </div>

        <div>
          <p>Hospitals</p>
          <ul>
            <li><a href='http://gsia.sums.ac.ir/en/page/18720/Nemazee-Hospital'>Nemazee Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18710/Shahid-Faghihi-Hospital--AKA-Sa-adi-Hospital-'>Shahid Faghihi Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18728/Shahid-Chamran-Hospital'>Shahid Chamran Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18725/Amir-Oncology-Hospital'>Amir Oncology Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18727/Emtiyaz-Hospital-and-Shahid-Rajaee-Surgery-Emergency-Center'>Emtiyaz Hospital and Shahid Rajaee Surgery Emergency Center</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18735/Khalili-Hospital'>Khalili Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18736/Hazrate-Ali-Asghar-Hospital'>Hazrate Ali-Asghar Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18740/Shahid-Dasgheib-Hospital'>Shahid Dasgheib Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18747/Hazrate-Zeinab-Hospital'>Hazrate Zeinab Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18749/Amir-Al-Moemenin-Hospital'>Amir-Al-Moemenin Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18737/Hafez-Hospital'>Hafez Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18729/Al-Zahra-and-Shahid-Hejazi-Hospital'>Al-Zahra and Shahid Hejazi Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18741/Shooshtari-Hospital'>Shooshtari Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18738/Ibn-e-Sina-Hospital'>Ibn-e-Sina Hospital</a></li>
            <li><a href='http://gsia.sums.ac.ir/en/page/18739/Ostad-Moharary-Neuropsychiatric-Hospital'>Ostad Moharary Neuropsychiatric Hospital</a></li>
          </ul>
        </div>
      </div>

      <div className='bg-[#2e2a29] text-[#999896] content-center'>
        <ul className='mb-2'>
          <li>
            <a href='http://www.sums.ac.ir/'>SMUS</a>
            <a href='http://gsia.sums.ac.ir/en/page/18432/Contact-Us'>&nbsp;| Contact Us |&nbsp;</a>
            <a href='http://gsia.sums.ac.ir/en/sitemap'>Sitemap</a>
          </li>
        </ul>

        <p>&copy; Copyright &copy;2024 sums.ac.ir All Rights Reserved. Powered by targan.ir Version 5.5.121<sup>®</sup> &nbsp;&nbsp;|&nbsp;&nbsp; کلیه حقوق این سامانه متعلق به دانشگاه علوم پزشکی و خدمات بهداشتی، درمانی شیراز می باشد.</p>
      </div>
    </footer>
  )
}

export default Footer
