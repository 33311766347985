import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    university: '',
}

const schemeSlice = createSlice({
    name: 'scheme',
    initialState,
    reducers: {
        setUniversityName: (state, action) => {
            state.university = action.payload
        },
    }
})

export const {
    setUniversityName,
} = schemeSlice.actions

export default schemeSlice.reducer