import React, { useState } from 'react'
import './Login.css'
import { TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Done } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from '../loader/Loader'

const Login = () => {

    const navigate = useNavigate()
    const universityName = useSelector(state => state.scheme.university)

    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [disableGenerate, setDisableGenerate] = useState(false)
    const [loader, setLoader] = useState(false)

    const validateEmail = () => (email.slice(-7) === '.edu.in' || email.slice(-8) === '.edu.com') && email.includes('mitwpu')

    const handleLogin = event => {
        event.preventDefault()

        setLoader(true)
        axios.post(`https://smu-international.vercel.app/api/otp/verify-otp`, { email, otp })
            .then(res => {
                if (res.data.success) {
                    console.log('res', res)
                    toast.success(res.data.message)
                    localStorage.setItem('token', res.data.token)
                    navigate('/international/our-students')
                }
                setLoader(false)
            })
            .catch(err => {
                console.log(err)
                toast.error(err.response.data.message)
                setLoader(false)
            })


        
    }

    const generateOtp = () => {
        
        if (validateEmail()) {

            if (universityName) {
                toast.error('Please Login with a valid email')
                return
            }

            setLoader(true)
            axios.post(`https://smu-international.vercel.app/api/otp/generate-otp`, { email })
                .then(res => {
                    toast.success('OTP generated successfully!')
                    setDisableGenerate(true)
                    setLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoader(false)
                })
        } else {
            toast.error('Please use your institute email to log in')
        }

    }

    return (
        <div className='login h-screen'>

            {loader && <Loader />}
            
            <div className='login__logo relative z-10 pt-20'></div>

            <div className='flex flex-col items-center justify-between h-[calc(100%-80px)]'>
                <div className='text-white content-center flex-col relative z-10 mt-10'>

                    <p className='text-[40px] font-[200] leading-[48px] max-w-[800px] login__header'>{universityName || 'Shiraz University' } WebMail</p>
                    <p className='login__subheader text-[20px] font-[200] mt-[10px] mb-[30px] text-[--color-login]'>
                        Log in using your institute email account
                    </p>

                    <form className='login__form w-[30vw]' onSubmit={handleLogin}>
                        <div className='mb-4 w-full'>
                            <TextField
                                id="email"
                                label="EMAIL"
                                fullWidth
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                placeholder='Ex. johndoe@stanford.edu'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: 'var(--color-login-labels)', // Change the outline border color
                                        },
                                        '&:hover fieldset': {
                                          borderColor: 'var(--color-login)', // Change the outline border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#7eadd9', // Change the outline border color when focused
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: 'var(--color-login-labels)',
                                        fontWeight: 400,
                                        fontFamily: "'Source Sans 3', sans-serif",
                                      },
                                      '&:hover .MuiInputLabel-root': {
                                        color: 'var(--color-login)'
                                      },
                                }}
                                InputProps={{
                                    style: {
                                        color: 'var(--color-login-labels)',
                                    }
                                }}
                                autoComplete='off'
                            />
                        </div>

                        <div className='w-full relative'>
                            <TextField
                                id="otp"
                                label="OTP"
                                fullWidth
                                value={otp}
                                onChange={event => setOtp(event.target.value)}
                                type='number'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: 'var(--color-login-labels)', // Change the outline border color
                                        },
                                        '&:hover fieldset': {
                                          borderColor: 'var(--color-login)', // Change the outline border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#7eadd9', // Change the outline border color when focused
                                        },
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: 'var(--color-login-labels)',
                                        fontWeight: 400,
                                        fontFamily: "'Source Sans 3', sans-serif",
                                      },
                                      '&:hover .MuiInputLabel-root': {
                                        color: 'var(--color-login)'
                                      },
                                }}
                                InputProps={{
                                    style: {
                                        color: 'var(--color-login-labels)'
                                    }
                                }}
                            />

                            <button disabled={disableGenerate} type='button' onClick={generateOtp} className={`bg-[#00BD68] ml-4 rounded-[25px] leading-[48px] min-w-[120px] ${disableGenerate && 'opacity-70'} hover:opacity-70 absolute left-full top-1/2 -translate-y-[50%]`}>
                                {disableGenerate ? <>Generated <Done /></> : 'Generate'}
                            </button>
                        </div>

                        <div className='text-center mt-[40px]'>
                            <button className='bg-[#dc6060] rounded-[25px] leading-[48px] min-w-[280px] hover:opacity-70'>LOG IN</button>
                        </div>

                        <div className='text-center my-10'>
                            <p className='text-[#757e80]'>
                                Back to
                                &nbsp;
                                <Link
                                    to='/'
                                    className='text-[#4c8ea6]'
                                >International Transfer Program
                                </Link>
                            </p>
                        </div>
                    </form>

                </div>

                <div className='text-[#757e80] text-[14px] font-[400] w-[500px] text-center items-end z-10 relative justify-self-end pb-4'>
                    <p className='border-b-[1px] border-[#757e80] pb-1'>
                        Powered by
                        &nbsp;
                        <a
                            href='https://www.axigen.com/?utm_source=product&utm_medium=webmail&utm_campaign=webmail_login_page_en'
                            target='_blank'
                            rel="noreferrer"
                            className='text-[#4c8ea6]'
                        >Axigen</a>
                    </p>

                    <p className='w-[500px] text-center leading-[16px] my-2'>
                        Email, calendaring, and collaboration
                        <br />
                        Total privacy, high availability, and scalability
                    </p>
                </div>

            </div>

        </div>
    )
}

export default Login
