import React from 'react'

const SecondaryNavbar = () => {
    return (
        <div className='bg-[#e36b2c] secondary-navbar px-[40px] content-center relative z-10 !hidden md:!block'>
            <ul>
                <li>
                    <p>Academics</p>
                    <div>
                        <ul>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>Schools</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18847/School-of-Medicine'>School of Medicine</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18848/School-of-Dentistry'>School of Dentistry</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/contact/18382/School-of-Pharmacy'>School of Pharmacy</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18849/School-of-Health'>School of Health</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18843/School-of-Rehabilitation-Sciences'>School of Rehabiliation Sciences</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18844/School-of-Nursing-and-Midwifery'>School of Nursing and Midwifery</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18845/School-of-Nutrition-and-Food-Sciences'>School of Nutrition and Food Sciences</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18842/School-of-Paramedical-Sciences'>School of Paramedical Sciences</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18841/School-of-Health-Management-and-Information-Sciences'>School of Health Management and Information Sciences</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/contact/18387/School-of-Advanced-Medical-Sciences-and-Technologies'>School of Advance Medical Science and Technology</a>
                            </li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>Programs</p>
                    <div>
                        <ul>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/'>Programs A-Z</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/'>Degree Programs</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/UniversityStudy/18424/Undergraduate'>Undergraduate</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/UniversityStudy/18459/Master-s'>Master's</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/UniversityStudy/18429/Ph-D-'>Ph.D.</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/UniversityStudy/18435/Specialty'>Speciality</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/UniversityStudy/18426/Subspecialty'>Subspeciality</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/UniversityStudy/18427/Fellowship'>Fellowship</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/'>Non-Degree Programs</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18316/Visiting-Medical-Electives--Internships-'>Visiting Medical Electives (Internships)</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18630/Visiting-Specialty-and-Subspecialty--Residency--Elective-Programs'>Visiting Specialty and Subspecialty (Residency) Elective Programs</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18317/Summer-Programs'>Summer Programs</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18315/Short-Term-Training-Programs'>Short-term Training Programs</a>
                            </li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>Admissions and Financial Aid</p>
                    <div>
                        <ul>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/'>Admissions Guidelines</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18318/Admissions-Guidelines'>Admissions Guidelines</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18457/CV-Template-and-Instructions'>CV Template and Instructions</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18457/CV-Template-and-Instructions'>Cover Letter Template and Instructions</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18461/Recommendation-Letter-Template-and-Instructions'>Recommendation Letter Template and Instructions</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/'>Requirements</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18326/Undergraduate'>Undergraduate Requirements</a>
                            </li>
                            <li>
                                <a href='https://gsia.sums.ac.ir/en/page/18327/Graduate'>Graduate Requirements</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18320/Tuition--Fees-and-Expenses'>Tuition, Fees and Expenses</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18321/Financial-Aid-and-Scholarships'>Financial Aid and Scholarships</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='http://gsia.sums.ac.ir/en/page/18860/Apply-to-SUMS'>Apply to SUMS</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='http://gsia.sums.ac.ir/en/page/18432/Contact-Us'>Contact Admission Team</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/faq/18324/FAQ'>FAQ</a>
                            </li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>Apply to SUMS</p>
                    <div>
                        <ul>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/form/18873/Apply-to-SUMS-Undergraduate-Programs--B-Sc---M-B-B-S---M-D---D-M-D---Pharm-D--'>Apply to SUMS Undergraduate Programs (B.Sc., M.B.B.S., M.D., D.M.D., Pharm.D.)</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/form/18468/Apply-to-SUMS-Graduate-Programs--M-Sc---Ph-D---Specialty--Subspecialty--Fellowship-'>Apply to SUMS Graduate Programs (M.Sc., Ph.D., Specialty, Subspecialty, Fellowship)</a>
                            </li>
                            <li>
                                <a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/form/18463/Apply-to-SUMS-Non-degree-Programs--Medical-Electives---Short-term-Training-Courses-'>Apply to SUMS Non-degree Programs (Medical Electives & Short-term Training Courses)</a>
                            </li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>Research</p>
                    <div>
                        <ul>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18719/Research-at-SUMS'>Research at SUMS</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18731/Mohammad-Rasoolullah--PBUH--Research-Tower'>Mohammad Rasoolullah (PBUH) Research Tower</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>Research Centers and Institutes</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18655/Clinical-Microbiology-Research-Center'>Clinical Microbiology Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18656/Shiraz-Institute-for-Cancer-Research'>Shiraz Institute for Cancer Research</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18658/Medicinal-and-Natural-Products-Chemistry-Research-Center'>Medicinal and Natural Products Chemistry Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18657/Shiraz-Transplant-Research-Center'>Shiraz Transplant Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18659/Health-Policy-Research-Center'>Health Policy Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18660/Shiraz-Burn-Research-Center'>Shiraz Burn Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18662/Nanomedicine-and-Nanobiology-Research-Center'>Nanomedicine and Nanobiology Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18661/Hematology-Research-Center'>Hematology Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18663/Gastroenterohepatology-Research-Center'>Gastroenterohepatology Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18664/Nanotechnology-in-Drug-Delivery-Research-Center'>Nanotechnology in Drug Delivery Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18667/Non-communicable-Diseases-Research-Center'>Non-communicable Diseases Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18668/Nutrition-Research-Center'>Nutrition Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18669/Rehabilitation-Sciences-Research-Center'>Rehabilitation Sciences Research Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18665/Clinical-Neurology-Research-Center'>Clinical Neurology Research Center</a></li>
                            <li><a href='http://gsia.sums.ac.ir/en/page/18439/SUMS-Research-Centers'>More</a></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <p>Healthcare</p>
                    <div>
                        <ul>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18752/Health-Care-System-at-SUMS'>Health-Care System at SUMS</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>Teaching Hospitals</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18720/Nemazee-Hospital'>Nemazee Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18710/Shahid-Faghihi-Hospital--AKA-Sa-adi-Hospital-'>Shahid Faghihi Hospital (AKA Sa'adi Hospital)</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18728/Shahid-Chamran-Hospital'>Shahid Chamran Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18725/Amir-Oncology-Hospital'>Amir Oncology Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18727/Emtiyaz-Hospital-and-Shahid-Rajaee-Surgery-Emergency-Center'>Emtiyaz Hospital and Shahid Rajaee Surgery Emergency Center</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18735/Khalili-Hospital'>Khalili Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18736/Hazrate-Ali-Asghar-Hospital'>Hazrate Ali-Asghar Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18740/Shahid-Dastgheib-Hospital'>Shahid Dastgheib Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18747/Hazrate-Zeinab-Hospital'>Hazrate Zeinab Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18749/Amir-Al-Moemenin-Hospital'>Amir-Al-Moemenin Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18737/Hafez-Hospital'>Hafez Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18729/Al-Zahra-and-Shahid-Hejazi-Hospital'>Al-Zahra and Shahid Hejazi Hospital</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18738/Ibn-e-Sina-Hospital'>Ibn-e-Sina Hospital</a></li>
                        </ul>
                    </div>
                </li>
                <li>
                    <p>Global</p>
                    <div>
                        <ul>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://www.sums.ac.ir/'>International Relations</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18831/Collaboration-and-Partnership'>Collaboration and Partnership</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18643/MOUs-and-Contracts'>MOUs and Contracts</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18645/Grants-and-Opportunities'>Grants and Opportunities</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18644/Visiting-Professor-Program'>Visiting Professor Program</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18649/International-Joint-Workshops'>International Joint Workshops</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18733/Webinars-and-Live-Events'>Webinars and Live Events</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18647/Collaboration-with-Non-resident-Iranian-Elites'>Collaboration with Non-resident Iranian Elites</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18714/Staff-with-Notable-International-Engagement'>Staff with Notable International Engagement</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18646/Contact-International-Relations-Team'>Contact International Relations Team</a></li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>Campus Life</p>
                    <div>
                        <ul>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>Campus services</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18360/Arrival'>Arrival</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18361/Accommodations'>Accommodations</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18362/Dining'>Dinning</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18363/Athletics-and-Recreations--Sports-and-Activities-'>Athletics and Recreations (Sports and Activities)</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18364/Health-Insurance'>Health Insurance</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18365/IT-Services'>IT Services</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18332/Registration'>Registration</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18333/Visa-Affairs'>Visa Affairs</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18488/Transportation-System-in-Shiraz'>Transportation System in Shiraz</a></li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>Media</p>
                    <div>
                        <ul>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/download/18613/Adobe-Connect'>Adobe Connect</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/news/18400/Announcements'>Announcements</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/page/18886/Newsletter'>Newsletter</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/download/18593/Marketing-Materials'>Marketing Materials</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/download/18443/Forms'>Forms</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>Videos</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18611/Introduction-to-SUMS'>Introduction to SUMS</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18824/Orientation-Program-2021'>Orientation Program 2021</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/en/imagegallery/18444/Picture-Gallery'>Picture Gallery</a></li>
                        </ul>

                    </div>
                </li>
                <li>
                    <p>About</p>
                    <div>
                        <ul>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>About SUMS</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18867/History---Overview'>History & Overview</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18650/Vision--Mission---Core-Values'>Vision, Mission & Core Values</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18881/University-Profile'>University Profile</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18486/Notable-Alumni'>Notable Alumni</a></li>
                            <li><a className='text-[#395883] hover:!text-[#005] font-[700]' href='https://gsia.sums.ac.ir/'>Administration</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18823/Chancellor'>Chancellor</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18778/Vice-Chancelleries'>Vice-Chancelleries</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18389/University-Bodies'>University Bodies</a></li>
                            <li><a href='https://gsia.sums.ac.ir/en/page/18850/Vice-Chancellor-for-Global-Strategies-and-International-Affairs'>Vice-Chancellor for Global Strategies and International Affairs</a></li>
                        </ul>

                    </div>
                </li>
            </ul>
        </div>
    )
}

export default SecondaryNavbar
