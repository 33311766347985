import React, { useState } from 'react'
import './Scheme.css'
import { Modal, Box, Autocomplete, TextField } from '@mui/material'
// import { countries } from '../../assets/utils'
import * as utils from '../../assets/utils'
import { ChevronRight } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUniversityName } from './SchemeSlice'

const schemeModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    outline: 'none'
}

const Scheme = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [schemeModalOpen, setSchemeModalOpen] = useState(false)
    const [country, setCountry] = useState('')
    const [inputCountry, setInputCountry] = useState('')
    const [university, setUniversity] = useState('')
    const [inputUniversity, setInputUniversity] = useState('')

    const handleSchemeModalOpen = () => {
        setSchemeModalOpen(true)
    }

    const handleUniversitySelect = () => {
        dispatch(setUniversityName(university))
        navigate('/institute/login')
    }
    
    const handleUniversityReset = () => {
        dispatch(setUniversityName(''))
        navigate('/institute/login')
    }

    return (
        <div className='min-h-[60vh] scheme__wrapper py-[40px]'>
            <div className='scheme bg-white shadow-[1px_1px_5px_#183a68] px-[20px] py-[10px]'>
                <p className=' text-[1rem] text-[#09347a] font-[700] mb-2 uppercase border-b-[2px] border-dotted pb-3'>International Transfer Programme (ITP)</p>

                <div className='flex mt-2 flex-col md:flex-row'>
                    <div className='border-r-[2px] border-dashed pl-4 py-2 pr-20'>
                        <button onClick={handleSchemeModalOpen} className='scheme__button mb-2'>Our Students</button>

                        <ul className='text-[#1c2754] font-bold text-[0.8rem] scheme__left-links'>
                            <li><a href='https://www.sums.ac.ir/'>Home</a></li>
                            <li><a href='https://www.sums.ac.ir/'>Undergraduate</a></li>
                            <li><a href='https://www.sums.ac.ir/'>Master's</a></li>
                            <li><a href='https://www.sums.ac.ir/'>Ph.D.</a></li>
                            <li><a href='https://www.sums.ac.ir/'>Specialty</a></li>
                            <li><a href='https://www.sums.ac.ir/'>Subspecialty</a></li>
                            <li><a href='https://www.sums.ac.ir/'>Fellowship</a></li>
                        </ul>
                    </div>

                    <div className='ml-4 text-[.9rem]'>
                        <p className='text-[9pt] leading-[15pt]'>Code :&nbsp;<span className='text-[#1e0a8f]'>m-28939</span> Created Date : <span className='text-[#1e0a8f]'>Saturday, March 19, 2022</span>&nbsp;&nbsp;Update Date : <span className='text-[#1e0a8f]'>Tuesday, December 30, 2023</span>&nbsp;&nbsp;Visit : <span className='text-[#1e0a8f]'>1107</span></p>

                        <h3 className='text-[red] text-[16px] font-bold text-center my-4'>Explore Global Opportunities with SMU's International Transfer Programme (ITP)</h3>

                        <p className='my-2'>
                            Unlock a world of possibilities through Shiraz Medical University's International Transfer Programme (ITP).
                            After completing one year of your medical studies at SMU, seize the chance to broaden your horizons
                            by studying at our esteemed partner universities. Choose from a diverse range of destinations
                            including Italy, Russia, China, UAE, Turkey, and many more.
                        </p>


                        <div>
                            <h3 className='scheme__heading'>International Transfer Programmes at SMU:</h3>

                            <p className='scheme__sub-heading'>
                                Embark on a transformative academic journey with our International Transfer Programmes.
                                SMU offers the ITP for the following programmes:
                            </p>

                            <ul className='my-2'>
                                <li><a href='https://www.sums.ac.ir/'>Doctor of Medicine</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Bachelor of Medicine and Bachelor of Surgery</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Biomedical Engineering</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Pharmacology</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Doctor of Pharmacy (Pharm.D.)</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Toxicology</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Pharmaceutical Biotechnology</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Clinical Pharmacy</a></li>
                                <li><a href='https://www.sums.ac.ir/'>Doctor of Dental Medicine (D.M.D.)</a></li>
                            </ul>
                        </div>

                        <div>
                            <h3 className='scheme__sub-heading'>Why Opt for International Programmes at SMU?</h3>

                            <p>
                                At SMU, we believe in providing our students with a global perspective in medical education. Opting for our International Programmes
                                comes with a myriad of advantages:
                            </p>

                            <ul>
                                <li>
                                    <strong>Diverse Learning Environments:</strong> Immerse yourself in diverse cultures and learning environments,
                                    gaining a well-rounded education that goes beyond textbooks.
                                </li>
                                <li>
                                    <strong>Cultural Exchange:</strong> Experience a rich cultural exchange that enhances your interpersonal skills and global awareness, crucial for a successful career in the medical field.
                                </li>
                                <li>
                                    <strong>Broaden Your Network:</strong> Build an international network of peers, professors, and professionals, opening doors to collaborative opportunities and friendships that last a lifetime.
                                </li>
                            </ul>

                            <div>
                                <h3 className='scheme__sub-heading'>Partner Universities:</h3>

                                <p>
                                    SMU collaborates with renowned universities worldwide to offer exceptional opportunities for our students. Some of our esteemed partner universities include:
                                </p>

                                <ul>
                                    <li><a href='https://gsia.sums.ac.ir/en/ndt/85/Assistant-Professor-of-the-University-of-Californi'>University of California</a></li>
                                    <li><a href='https://gsia.sums.ac.ir/en/srch?harvard'>Harvard University</a></li>
                                    <li><a href='https://gsia.sums.ac.ir/en/ndt/199/Academic-and-Healthcare-Partnerships-Discussed-at-'>University of Oxford</a></li>
                                    <li><a href='https://www.sums.ac.ir/'>Lomonosow Moscow State University</a></li>
                                    <li><a href='https://www.sums.ac.ir/'>University of Sydney</a></li>
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </div>



            {/* Scheme Modal */}

            <Modal
                open={schemeModalOpen}
                onClose={() => setSchemeModalOpen(false)}
                aria-labelledby="scheme-modal-title"
                aria-describedby="scheme-modal-description"
            >
                <Box sx={schemeModalStyle}>
                    <div className='mb-8'>
                        <p className='mb-4 text-[--color-primary]'>Please select the country</p>

                        <div>
                            <Autocomplete
                                freeSolo
                                id="country-list"
                                disabled={university?.length > 0}
                                options={utils.acceptedCountries}
                                value={country}
                                onChange={(_, newValue) => setCountry(newValue)}
                                inputValue={inputCountry}
                                onInputChange={(_, newValue) => setInputCountry(newValue)}
                                sx={{ width: 300 }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Country"
                                        placeholder='Select a country'
                                    />
                                }
                            />
                        </div>
                    </div>

                    {
                        country &&
                        <div>
                            <p className='mb-4 text-[--color-primary]'>Please select the university</p>

                            <div>
                                <Autocomplete
                                    freeSolo
                                    id="university-list"
                                    options={utils[country] || []}
                                    value={university}
                                    onChange={(_, newValue) => setUniversity(newValue)}
                                    inputValue={inputUniversity}
                                    onInputChange={(_, newValue) => setInputUniversity(newValue)}
                                    sx={{ width: 300 }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="University"
                                            placeholder='Select university'
                                        />
                                    }
                                />
                            </div>

                            {
                                university && 
                                    <div className="schema__our-students flex" onClick={handleUniversitySelect}>
                                        <ChevronRight /><span>Our students at {university}</span>
                                    </div>
                            }

                            <p className='mt-8 text-[12px] text-red-500'>Cannot find your University? <span onClick={handleUniversityReset} className='scheme__button ml-2'>Click Here</span></p>
                        </div>
                    }


                </Box>
            </Modal>
        </div>
    )
}

export default Scheme
