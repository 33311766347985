import { Outlet } from 'react-router-dom';
import './App.css';
import { Footer, Header } from './components';

function App() {
  return (
    <div>
      <Header />
      <div className="px-[50px] md:px-[100px] lg:px-[200px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
