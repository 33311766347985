import React from 'react'
import './Loader.css'

const Loader = () => 
    <div className="loader__container">
        <div className="loader">
            {
                Array.from({ length: 12 }).map((_, index) => 
                    <div
                        key={index}
                        className='loader__bar'
                        style={{ transform: `rotate(${index * 30}deg)` }}
                    />
                )
            }
        </div>
    </div>

export default Loader