export const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua & Deps", "Argentina", "Armenia",
    "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus",
    "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia Herzegovina", "Botswana", "Brazil",
    "Brunei", "Bulgaria", "Burkina", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde",
    "Central African Rep", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Congo {Democratic Rep}",
    "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica",
    "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea",
    "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany",
    "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
    "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland {Republic}", "Israel", "Italy",
    "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea North",
    "Korea South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia",
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macedonia", "Madagascar", "Malawi", "Malaysia",
    "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar, {Burma}", "Namibia",
    "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman",
    "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland",
    "Portugal", "Qatar", "Romania", "Russian Federation", "Rwanda", "St Kitts & Nevis", "St Lucia",
    "Saint Vincent & the Grenadines", "Samoa", "San Marino", "Sao Tome & Principe", "Saudi Arabia", "Senegal",
    "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
    "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland",
    "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga",
    "Trinidad & Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine",
    "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu",
    "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
]

export const acceptedCountries = [
    'Argentina',
    'Australia',
    'Austria',
    'Belgium',
    'Brazil',
    'Bulgaria',
    'Cameroon',
    'Canada',
    'Chile',
    'China',
    'Colombia',
    'Costa Rica',
    'Croatia',
    'Czech Republic',
    'Denmark',
    'Egypt',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Ghana',
    'Greece',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Ireland',
    'Italy',
    'Jamaica',
    'Japan',
    'Kenya',
    'Lebanon',
    'Lithuania',
    'Malaysia',
    'Mexico',
    'Netherlands',
    'New Zealand',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Serbia',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sweden',
    'Switzerland',
    'Taiwan',
    'Thailand',
    'Uganda',
    'Turkey',
    'Tunisia',
    'UK',
    'US',
    'Vietnam',

]

export const Argentina = [
    "University Buenos Aires (UBA)",
    "University National La Plata"
];

export const Australia = [
    "Australian Catholic University",
    "Australian National University",
    "Bond University",
    "Central Queensland University",
    "Charles Darwin University",
    "Curtin University",
    "Deakin University",
    "Edith Cowan University",
    "Flinders University",
    "Griffith University",
    "James Cook University",
    "La Trobe University",
    "Macquarie University",
    "Monash University",
    "Murdoch University",
    "Queensland University of Technology",
    "RMIT University",
    "Southern Cross University",
    "Swinburne University Technology",
    "UNSW Sydney",
    "University of Adelaide",
    "University of Canberra",
    "University of Melbourne",
    "University of New England Australia",
    "University of New South Wales",
    "University of Newcastle Australia",
    "University of Queensland",
    "University of South Australia",
    "University of Sydney"
];

export const Austria = [
    "Karl-Franzens University Graz",
    "Med University Graz",
    "Med University Innsbruk",
    "Med University Vienna",
    "Paracelsus Private Medical University",
    "University Graz",
    "University Innsbruk",
    "University of Natural Resources and Life Sciences, Vienna",
    "University of Vienna"
];

export const Belgium = [
    "Ghent University",
    "Hasselt University",
    "KU Leuven",
    "University Cathalique de Louvain (UCL)",
    "University de Liege",
    "University Libre de Bruxelles",
    "University of Antwerp",
    "Vrije Universiteit Brussel (VUB)"
];

export const Brazil = [
    "Ceará State University",
    "Faculdade Sao Leopoldo Mandic",
    "Federal University of Bahia",
    "Federal University of Ceare (UFC)",
    "Federal University of Maranhao",
    "Federal university of Pelotas",
    "Fluminense Federal University",
    "Guarulhos University",
    "Pontificial Universidade Catolica do Rio Grande do Sul (PUCRS)",
    "State University of Maringa",
    "State University Ponta Grossa",
    "Universidade de Sao Paulo",
    "UNESP",
    "Unigranrio University",
    "Universidade de Passo Fundo",
    "Universidade do Estado do Rio de Janeiro (UERJ)",
    "Universidade Estacio de Sa",
    "Universidade Estadual de Campinas (unicamp)",
    "Universidade Estadual de Londrina",
    "Universidade Federal Da Paraiba",
    "Universidade Federal de Minas Gerais",
    "Universidade Federal de Santa Catarina",
    "Universidade Federal de Sao Paulo",
    "Universidade Federal de Uberlandia",
    "Universidade Federal do Parana (UFPR)",
    "Universidade Federal do Rio Janeiro",
    "Universidade Federal do Rio Grande do Sul (UFRGS)",
    "Universidade Luterana do Brasil",
    "University Brasilia",
    "University Estadual Paulista",
    "University Federal de Pernambuco (UFPE)"
];

export const Bulgaria = [
    "Medical University Sofia"
];

export const Cameroon = [
    "University of Yaounde I"
];

export const Canada = [
    "Dalhousie University",
    "Laval University",
    "McGill University",
    "McMaster University",
    "Memorial University of Newfoundland",
    "Queen's University at Kingston",
    "Simon Fraser University",
    "Universite de Sherbrooke",
    "University de Montreal",
    "University de Quebec a Montreal",
    "University Guelph",
    "University of Alberta",
    "University of British Columbia",
    "University of Calgary",
    "University of Manitoba",
    "University of Ottawa",
    "University of Toronto",
    "University of Victoria",
    "University of Saskatechewan",
    "University of Waterloo",
    "Western University",
    "York University"
];

export const Chile = [
    "Pontificia Universidad Catolica de Chile (UC)",
    "University de Los Andes- Chile",
    "University of Chile",
    "University of Desarrolo",
    "University of La Frontera"
];

export const China = [
    "Anhui Medical University",
    "Army Medical University",
    "Beijin Normal University",
    "Beijing University of Chinese Medicine",
    "Capital Medical University",
    "Central South University",
    "China agricultural university",
    "China Med University",
    "China Pharmaceutical University",
    "Chinese University Hong Kong",
    "Chongqing Medical University",
    "Dalian Medical University",
    "East China Normal University",
    "East China University of Science and Technology",
    "Fudan University",
    "Guangzhou Medical University",
    "Harbin Medical University",
    "Huazhong Agricultural University",
    "Huazhong University of Science and Technology",
    "Jiangsu University",
    "Jilin University",
    "Jinan University",
    "Nanjing Agriculture University",
    "Nanjing Medical University",
    "Nanjing University",
    "Nanjing University of Chinese Medicine",
    "Nankai University",
    "Nantong university",
    "Northwest A&F University",
    "Ocean University of China",
    "Peking University",
    "Peking Union Medical College",
    "Shandong University",
    "Shanghai jiao Tong University",
    "Shanghai University of Traditional Chinese Medicine",
    "Shantou University",
    "SiChuan University",
    "Soochow University",
    "South China University of Technology",
    "Southeast University",
    "Southern Medical University",
    "Southwest University",
    "Sun Yat-Sen University",
    "Tianjin Medical University",
    "Tianjin University",
    "Tongji University",
    "Tsinghua University",
    "University Hong Kong",
    "University of Science and Technology of China",
    "Wenzhou Medical University",
    "Wuhan University",
    "Xiamen University",
    "Xian jiaotong University",
    "Zhejiang University",
    "Zhejiang University of Technology",
    "Zhengzhou University"
];

export const Colombia = [
    "Pontificia University Javeriana",
    "University National of Colombia",
    "University of The Andes",
    "University of Antioquia",
    "University the Roseiro",
    "University the Valle"
];

export const CostaRica = [
    "University of Costa Rica"
];

export const Croatia = [
    "University of Split",
    "University of Zagreb"
];

export const CzechRepublic = [
    "Academy of Sciences of the Czech Republic",
    "Charles University",
    "Masaryk University Brno",
    "Palaky University in Olomouc",
    "University of Ostrava"
];

export const Denmark = [
    "Aalborg University",
    "Aarhus University",
    "Roskilde University",
    "Technical University of Denmark",
    "University of Copenhagen",
    "University of Southern Denmark"
];

export const Egypt = [
    "Al-Azhar University",
    "Ain Shams University",
    "Alexandria University",
    "Assiut University",
    "Beni-Suef University",
    "Cairo University",
    "Helwan University",
    "Mansoura University",
    "Minia University",
    "Suez Canal University"
];

export const Estonia = [
    "University of Tartu"
];

export const Finland = [
    "University Jyvaskyla",
    "University of Eastern Finland",
    "University of Helsinki",
    "University of Oulu",
    "University of Tempere",
    "University of Turku"
];

export const France = [
    "Aix-Marseille University",
    "Federal University of Toulouse midi",
    "National Polytechnic Institute of Toulouse",
    "Normandy University",
    "Sorbonne University",
    "University Burgundy",
    "University Claude Bernard Lyon 1",
    "University Lorraine",
    "University de Versailles Saint Quentin En Yvelines",
    "University Grenoble Alpes",
    "University of Angers",
    "University of Bordeaux",
    "University of Lille",
    "University of Montpellier",
    "University of Nantes",
    "University of Rennes 1",
    "University of Strasbourg",
    "University of Tours",
    "University of Côte d'Azur",
    "University Paris 13 Nord",
    "University Paris Descartes",
    "University Paris Didort-Paris 7",
    "University Paris-Est Creteil Val de Marne",
    "University Paris-Saclay",
    "University Paris-Sud",
    "University Paul Sabatier Toulouse",
    "University PSL"
];

export const Germany = [
    "Albert Ludwigs University Freiburg",
    "Bielefeld University",
    "Charite University Medicine Berlin",
    "Christian Albrechts University Zu Kiel",
    "Eberhard Krls University Tubingen",
    "Free University of Berlin",
    "Friedrich Alexander University Erlangen Nurnberg",
    "Goethe University Frankfurt",
    "Hannover Medical School",
    "Humboldt University Zu Berlin",
    "Johannes Gutenberg University Mainz",
    "Justus Leibig University Giessen",
    "Karlsruhe Inst Technology",
    "Martin Luther University Halle Wittenberg",
    "Otto Von Guericke University of Magdeburg",
    "Philipps University Marburg",
    "Reprecht Karls University Heidelberg",
    "Ruhr University Bochum",
    "RWTH Aachen University",
    "Technical University of Berlin",
    "Technical University of Munich",
    "Technische University Dresden",
    "Technology University Dortmund",
    "TU Dresden",
    "University des Saarlandes",
    "University Duesseldorf",
    "University Hamburg",
    "University Jena",
    "University Konstanz",
    "University of Cologne",
    "University of Duisburg Essen",
    "University of Gottingen",
    "University of Greifswald",
    "University of Hohenheim",
    "University of Humburg",
    "University of Leipzig",
    "University of Luebeck",
    "University of Muenster",
    "University of Munich",
    "University of Rostock",
    "University of Wurzburg",
    "University of bonn",
    "University of Regensburg",
    "University Ulm",
    "Westfalische Wilhelms University Munster",
    "Witten Herdecke University"
];

export const Ghana = [
    "University of Cape Coast",
    "University of Ghana"
];

export const Greece = [
    "Aristotle University of Thessaloniki",
    "Democritus University of Thrace",
    "National and Kapodistrian University of Athens",
    "University Ioannina",
    "University of Crete",
    "University of Patras",
    "University Thessaly"
];

export const HongKong = [
    "City University of Hong Kong",
    "Hong kong Baptist University",
    "The Chinese University of Hong Kong (CUHK)",
    "The Hong Kong Polytechnic University",
    "The Hong Kong University of Science and Technology",
    "The University of Hong Kong"
];

export const Hungary = [
    "Semmelweis University Budapest",
    "University Debrecen",
    "University of Pecs",
    "University Szeged"
];

export const Iceland = [
    "University of Iceland"
];

export const India = [
    "Aligarh Muslim University",
    "All India Institute Med Sciences New Delhi",
    "Banaras Hindu University",
    "Christian Medical College & Hospital (CMCH) Vellore",
    "Manipal Academy of Higher Education",
    "Panjab University",
    "University of Delhi"
];

export const Indonesia = [
    "University of Indonesia",
    "Gadjah Mada University"
];

export const Ireland = [
    "Dublin City University",
    "National University of Ireland Galway",
    "Royal College of Surgeons in Ireland (RCSI)",
    "Trinity College Dublin",
    "University College Cork",
    "University College Dublin",
    "University of Limerick"
];

export const Italy = [
    "Alma Master Studiorum University of Bologna",
    "Amedeo Avogadro University of Eastern Piedmont",
    "Catholic University of The Sacred Heart",
    "Gabriele d'Annunzio University",
    "Magna Graecia University of Catanzaro",
    "Marche Polytechnic University",
    "Perugia University",
    "Sant'Anna School of Advanced Studies - Pisa",
    "Sapienza University of Rome",
    "University Cattolica del Sacro Coure",
    "University L'Aquila",
    "University of Bari",
    "University of Brescia",
    "University of Cagliari",
    "University of Calabria",
    "University of Camerino",
    "University of Campania Luigi Vanvitelli",
    "University of Catania",
    "University of Chieti-Pescara",
    "University of Degli Studi di Udine",
    "University of Eastern Piedmont",
    "University of Ferrara",
    "University of Florence",
    "University of Genoa",
    "University of Insubria",
    "University of Messina",
    "University of Milan",
    "University of Modena and Reggio Emilia",
    "University of Naples-Federico II",
    "University of Padua",
    "University of Palermo",
    "University of Parma",
    "University of Pisa",
    "University of Pavia",
    "University of Rome “Tor Vergata”",
    "University of Salerno",
    "University of Sassari",
    "University of Siena",
    "University of Trieste",
    "University of Turin",
    "University of Vita-Salute san Raffaele",
    "University of Milan Bicocca",
    "Verona University"
];

export const Jamaica = [
    "The University of the West Indies"
];

export const Japan = [
    "Aichi Gakuin University",
    "Aichi medical University",
    "Akita University",
    "Asahi University",
    "Chiba University",
    "Ehime University",
    "Fujita health university",
    "Fukuoka Dental College (FDC)",
    "Gifu University",
    "Gunma University",
    "Hamamatsu University School of Medicine",
    "Health Sciences University of Hokkaido",
    "Hiroshima University",
    "Hokkaido University",
    "Hyogo College of Medicine",
    "Jikei University School of Medicine",
    "Juntendo University",
    "Kagawa University",
    "Kagoshima University",
    "Kanazawa University",
    "Kansai Medical University",
    "Keio University",
    "Kindai University",
    "Kitasato University",
    "Kobe University",
    "Kochi University",
    "Kumamoto University",
    "Kurume University",
    "Kyoto Prefectural University of Medicine",
    "Kyoto University",
    "Kyusho University",
    "Kyushu Dental University",
    "Iwate Medical University",
    "Mie University",
    "Nagasaki University",
    "Nagoya City University",
    "Nagoya University",
    "Nara Medical University",
    "Nihon University",
    "Niigata University",
    "Nippon Medical School",
    "Oita University",
    "Okayama University",
    "Osaka City University",
    "Osaka Medical College",
    "Osaka Prefecture University",
    "Osaka University",
    "Ritsumeikan University",
    "Saga University",
    "Saitama Medical University",
    "Sapporo Medical University",
    "Shiga University of Medical Science",
    "Shimane University",
    "Shinshu University",
    "Showa University",
    "Teikyo University",
    "The University of Tokyo",
    "Toho University",
    "Tohoku University",
    "Tokai University",
    "Tokushima University",
    "Tokyo Dental University",
    "Tokyo Institute Technology",
    "Tokyo Medical and Dental University (TMDU)",
    "Tokyo Medical University",
    "Tokyo Metropolitan University",
    "Tokyo University of Pharmacy and Life Science",
    "Tokyo University of Science",
    "Tottori University",
    "Tsurumi University",
    "University of Fukui",
    "University of Miyazaki",
    "University of Occupational and Enviromental Health",
    "University of Shizuoka",
    "University of the Ryukyus",
    "University of Toyama",
    "University of Tsukuba",
    "University of Yamanashi",
    "Waseda University",
    "Yamagata University",
    "Yamaguchi University",
    "Yokohama City University"
];

export const Kenya = [
    "University of Nairobi"
];

export const Lebanon = [
    "American University of Beirut (AUB)",
    "Lebanese American University",
    "Saint joseph University of Beirut (USJ)"
];

export const Lithuania = [
    "Lithuanian University of Health Sciences",
    "Vilnius University"
];

export const Malaysia = [
    "National University of Malaysia",
    "University of Science, Malaysia",
    "University Kebangsaan Malaysia (UKM)",
    "University Malaysia (UM)",
    "University Putra Malaysia (UPM)",
    "University Sains Malaysia (USM)",
    "University Teknologi MARA"
];

export const Mexico = [
    "Institute Polytechnic National (IPN)",
    "Institute Technology de Studios Superiores de Monterrey",
    "Metropolitan Autonomous University",
    "University National Autonomous of Mexico (UNAM)",
    "University of Guadalajara"
];

export const Netherlands = [
    "Academic Center for Dentistry Amesterdam",
    "Delft University Technology",
    "Erasmus University Rotterdam",
    "Leiden University",
    "Maastricht University",
    "Radboud University",
    "Tilburg University",
    "University of Amsterdam",
    "University of Groningen",
    "University of Twente",
    "Utrecht University",
    "Vrije University Amsterdam",
    "Wageningen University & Research Center"
];

export const NewZealand = [
    "Auckland University of Technology",
    "Massey University",
    "The University of Auckland",
    "University of Otago",
    "University of Conterbury"
];

export const Nigeria = [
    "University of Ibadan",
    "University of Lagos",
    "University of Nigeria Nsukka"
];

export const Norway = [
    "Norwegian University of Science and Technology",
    "University of Bergen",
    "University of Oslo",
    "University of Stavanger",
    "University of Tromso The Arctic"
];

export const Oman = [
    "Sultan Qaboos University"
];

export const Pakistan = [
    "Aga Khan University",
    "Quaid-i-azam University",
    "University of Kerachi",
    "University of Lahore"
];

export const Peru = [
    "Universidad Peruana Cayetano Heredia (UPCH)"
];

export const Philippines = [
    "University of Philippines"
];

export const Poland = [
    "Jagiellonian University",
    "University of Medical Lodz",
    "Medical University of Warsaw",
    "Nicolavs Copernicus University in Torun",
    "Wroclaw Medical University",
    "University of Gdansk"
];

export const Portugal = [
    "Universadade de Beira Interior",
    "Universidade Nova de Lisbon",
    "University of Algarve",
    "University of Aveiro",
    "University of Coimbra",
    "University of Lisbon",
    "University of Minho",
    "University of Porto",
    "University of Tras-os-Montes & Alto Douro"
];

export const Qatar = [
    "Qatar University",
    "Weill Cornell Medical College Qatar"
];

export const Romania = [
    "Carol Davila University of Medicine and Pharmacy",
    "Grigore T. Popa University of Medicine and Pharmacy",
    "Universitatea de Medicina si Farmacie Iuliu Hatieganu Cluj-Napoca",
    "Victor Babes University of Medicine & Pharmacy, Timisoara"
];

export const Russia = [
    "A.I Evdokimov Moscow State University of Medicine and Dentistry",
    "Kazan Federal University",
    "Lomonosow Moscow State University",
    "Novosibirsk State University",
    "Pavlov First Saint Petersburg State medical University",
    "Pirogov Russian National Research Medical University (RNRMU)",
    "Saint Petersburg State University",
    "Tomsk State University"
];

export const Serbia = [
    "University Belgrade",
    "University of Kragujevac"
];

export const Singapore = [
    "Nanyang Technological University",
    "National University of Singapore"
];

export const Slovakia = [
    "Comenius University"
];

export const Slovenia = [
    "University of Ljubljana",
    "University of Maribor"
];

export const SouthAfrica = [
    "North-West University",
    "Stellenbosch University",
    "University of Cape Town",
    "University of Johannesburg",
    "University of Kwazulu-Natal",
    "University of Pretoria",
    "University of the Western Cape",
    "University of the Witwatersrand"
];

export const SouthKorea = [
    "Ajou University",
    "Chonbuk National University",
    "Chonnam National University",
    "Chung-Ang University",
    "Chungbuk National University",
    "Chungnam National University",
    "Dankook University",
    "Ewha Womans University",
    "Gachon University of Medicine and Science",
    "Hallym University",
    "Hanyang University",
    "Inha University",
    "Inje University",
    "Jeonbuk National University",
    "KAIST University",
    "Kangwon National University",
    "Konkuk University",
    "Korea University",
    "Kyung Hee University",
    "Kyungpook National Universiy",
    "Pohang University Science & Technology",
    "Pusan National University",
    "Seoul National University",
    "Sungkyunkwan University (SKKU)",
    "The Catholic University Korea",
    "University of Ulsan",
    "Wonkwang University",
    "Yeungnam University",
    "Yonsei University"
];

export const Spain = [
    "Miguel Hernandez University of Elche",
    "Universitat Internacional de Catalunya (UIC)",
    "Universidad Autonoma de Barcelona",
    "Universidad Autonoma de Madrid",
    "University Balearic Islands",
    "University Complutense Madrid",
    "University of A Coruna",
    "University of Alcala",
    "University of Alicante",
    "University of Barcelona",
    "University of Cadiz",
    "University of Castilla-La Mancha",
    "University of Cordoba",
    "University of Girona",
    "University of Granada",
    "University of Jaen",
    "University of La Laguna",
    "University of Las Palmas de Gran Canaria",
    "University of León",
    "University of Malaga",
    "University of Murcia",
    "University of Navarra",
    "University of Oviedo",
    "University of Rovira I Virgili",
    "University of Salamance",
    "University of Santiago de Compostela",
    "University of Seville",
    "University of the Basque Country",
    "University of Valencia",
    "University of Valladolid",
    "University of Zaragoza",
    "University Pompeu Fabra",
    "University Rey Juan Carlos"
];

export const Sweden = [
    "Karolinska Institute",
    "Linkoping University",
    "Lund University",
    "Malmo University",
    "Orebro University",
    "Stockholm University",
    "Sweden University Agricultural Science",
    "Umea University",
    "University of Gothenburg",
    "Uppsala University"
];

export const Switzerland = [
    "Ecole Pilytechnic Federal Lausanne",
    "ETH Zurich-Swiss Federal Institute of Technology",
    "Swiss Federal Institute of Technology Laussane",
    "University of Basel",
    "University of Bern",
    "University of della Svizzera italiana",
    "University of Fribourg",
    "University of Geneva",
    "University of Lausanne",
    "University of Zurich"
];

export const Taiwan = [
    "Asia University Taiwan",
    "Chang Gung University",
    "China Medical University",
    "Chung Shan Medical University",
    "Fu jen Catholicuni University",
    "I-Shou University",
    "Kaohsiung Medical University",
    "National Cheng Kung University (NCKU)",
    "National Chung Hsing University",
    "National Taiwan University (NTU)",
    "National Tsing Hua University",
    "National Yang Ming University",
    "Taipei Medical University"
];

export const Thailand = [
    "Chiang Mai University",
    "Chulalongkorn University",
    "Khon Kaen University",
    "Mahidol University",
    "Naresuan University",
    "Prince of Songkla University",
    "Thammasat University"
];

export const Uganda = [
    "Makerere University"
];

export const Turkey = [
    "Acıbadem University",
    "Ataturk University",
    "Ankara University",
    "Bahçeşehir University",
    "Başkent University",
    "Dokuz Eylül University",
    "Ege university",
    "Gazi University",
    "Hacettepe university",
    "Istanbul University",
    "Istanbul Aydın University",
    "Izmir Katip Celebi University",
    "Koc University",
    "Marmara University",
    "Medipol University",
    "Ondokuz Mayis University",
    "Yeditepe University"
];

export const Tunisia = [
    "University of Sousse"
];

export const UK = [
    "Anglia Ruskin University",
    "Aston University",
    "Bangor University",
    "Bournemouth University",
    "Brighton and Sussex Medical School",
    "Brunel University London",
    "Cardiff University",
    "City. University of London",
    "Coventry University",
    "Durham University",
    "Edinburgh Napier University",
    "Edge Hill University",
    "Glasgow Caledonian University",
    "Imperial College London",
    "Keele University",
    "Kingston University",
    "King's College London",
    "Lancaster University",
    "Leeds Beckett University",
    "Liverpool John Moores University",
    "Liverpool School of Tropical Medicine",
    "London School of Hygiene and Tropical Medicine",
    "London South Bank University",
    "Loughborough University",
    "Manchester Metropolitan University",
    "Newcastle University",
    "Northumbria University",
    "Oxford Brookes University",
    "Plymouth University",
    "Queen Mary University of London",
    "Queen’s University Belfast",
    "Sheffield Hallam University",
    "St.George’s, University of London",
    "Swansea University",
    "The University of Exeter",
    "The University of Manchester",
    "The University of Sheffield",
    "The University of Warwick",
    "Ulster University",
    "University College London (UCL)",
    "University Kent",
    "University of Aberdeen",
    "University of Andrews",
    "University of Bath",
    "University of Birmingham",
    "University of Bradford",
    "University of Brighton",
    "University of Bristol",
    "University of Cambridge",
    "University of Central Lancashire",
    "University of Dundee",
    "University of East Anglia (UEA)",
    "University of Edinburgh",
    "University of Essex",
    "University of Glasgow",
    "University of Greenwich",
    "University of Hertfordshire",
    "University of Huddersfield",
    "University of Hull",
    "University of Leeds",
    "University of Leicester",
    "University of Lincoln",
    "University of Liverpool",
    "University of Nottingham",
    "University of Oxford",
    "University of Portsmouth",
    "University of Reading",
    "University of Salford",
    "University of Southampton",
    "University of Stirling",
    "University of Strathclyde",
    "University of Surrey",
    "University of Sussex",
    "University of the West of England",
    "University of York"
];

export const US = [
    "Albert Einstein College of Medicine",
    "Arizona State University",
    "Auburn University",
    "Augusta University",
    "Baylor College of Medicine",
    "Baylor University",
    "Binghamton University, State University of New York",
    "Boston College",
    "Boston University",
    "Brandeis University",
    "Brigham Young University",
    "Brown University",
    "California Institute of Technology (Caltech)",
    "Carnegie Mellon University",
    "Case Western Reserve University",
    "Clemson University",
    "Colorado State University",
    "Columbia University",
    "Cornell University",
    "Creighton University",
    "Dartmouth college",
    "Drexel University",
    "Duke University",
    "Emory University",
    "Florida Atlantic University",
    "Florida International University",
    "Florida State University",
    "Fort Collins, Colorado State University",
    "George Mason University",
    "George Washington University",
    "Georgetown University",
    "Georgia Institute of Technology",
    "Georgia State University",
    "Harvard University",
    "Hofstra University",
    "Howard University",
    "Icahn School of Medicine at Mount Sinai",
    "Indian University Purdue University at Indianapolis",
    "Indiana University Bloomington",
    "Iowa State University",
    "Johns Hopkins University",
    "Kansas State University",
    "Kent State University",
    "Loma Linda University",
    "Louisiana State University",
    "Loyola University Chicago",
    "Marquette University",
    "Massachusetts Institute of Technology (MIT)",
    "Mayo Clinic Alix School of Medicine",
    "Mayo Medical University",
    "Medical College Wisconsin",
    "Medical University of South Carolina",
    "Michigan State University",
    "Montana State University",
    "New York Medical College",
    "New York University (NYU)",
    "North Carolina State University",
    "Northeastern University",
    "Northwestern University",
    "Nova Southeastern University",
    "Oakland University",
    "Ohio State University (Main camp United States)",
    "Oklahoma State University",
    "Old Dominion University",
    "Oregon Health and Science University",
    "Oregon State University",
    "Penn State (Main campus)",
    "Pennsylvania State University",
    "Princeton University",
    "Purdue University",
    "Rice University",
    "Rush University",
    "Rutgers University",
    "Rutgers University New Brunswick",
    "Rutgers, the State University of New Jersey",
    "Saint Louis University",
    "San Diego State University",
    "Saint John's University",
    "Stanford University",
    "State University of New York Albany",
    "State University of New york Health Sciences Center at Brooklyn",
    "Stony Brook University, State University of New York",
    "Temple University",
    "Texas A&M Health Science Center",
    "Texas A&M University",
    "Texas State University",
    "Texas Technology University",
    "The Ohio State University",
    "The Rockefeller University",
    "The Scripps Research Institute",
    "The University of Arizona",
    "The University of Tennessee, Knoxville",
    "The University of Texas Health Science Center at Houston",
    "The University of Texas Medical Branch at Galveston",
    "The University Texas M.D.Anderson Cancer",
    "The University Texas Southwestern Medical Center at Dallas",
    "Thomas Jefferson University",
    "Tufts University",
    "Tulane University",
    "Uniformed Services University of the Health Sciences-USA",
    "University Vermont",
    "University at Buffalo, the State University of New York (SUNY)",
    "University Massachusetts Amherst",
    "University at Albany",
    "University of Alabama Birmingham",
    "University of Alabama",
    "University of Arkansas",
    "University of Arkansas for Medical Sciences",
    "University of California, Berkeley",
    "University of California, Davis",
    "University of California, Irvine",
    "University of California, Los Angeles (UCLA)",
    "University of California, Riverside",
    "University of California, San Diego (UCSD)",
    "University of California, San Francisco",
    "University of California, Santa Barbara",
    "University of California, Santa Cruz",
    "University of Central Florida",
    "University of Chicago",
    "University of Cincinnati",
    "University of Colorado Anschutz Medical Campus",
    "University of Colorado Boulder",
    "University of Colorado health Science Center",
    "University of Colorado, Denver",
    "University of Connecticut",
    "University of Delaware",
    "University of Florida",
    "University of Georgia",
    "University of Hawai’I at Manoa",
    "University of Houston",
    "University of Illinois at Chicago",
    "University of Illinois at Urbana-Champaign",
    "University of Iowa",
    "University of Kansas",
    "University of Kentucky",
    "University of Louisiana at Lafayette",
    "University of Louisville",
    "University of Maryland, Baltimore County",
    "University of Maryland, College Park",
    "University of Massachusetts",
    "University of Massachusetts Medical School Worcester",
    "University of Massachusetts-Boston",
    "University of Miami",
    "University of Michigan",
    "University of Minnesota",
    "University of Minnesota Twin Cities",
    "University of Michigan-Ann Arbor",
    "University of Mississippi",
    "University of Mississippi Medical Center",
    "University of Missouri",
    "University of Missouri Kansas City",
    "University of Missouri, Columbia",
    "University of Nebraska Lincoln",
    "University of Nebraska Medical Center",
    "University of Nevada-Las Vegas",
    "University of Nevada, Reno",
    "University of New Mexico",
    "University of New York City",
    "University of North Carolina at Charlotte",
    "University of North Carolina at Greensboro",
    "University of North Carolina, Chapel Hill",
    "University of Notre Dame",
    "University of Oklahoma",
    "University of Oklahoma Health Sciences Center",
    "University of Oregon",
    "University of Pennsylvania",
    "University of Pittsburgh",
    "University of Rhode Island",
    "University of Rochester",
    "University of South Alabama",
    "University of South Carolina",
    "University of South Dakota",
    "University of South Florida",
    "University of Southern California",
    "University of Tennessee Health Science Center",
    "University of Texas at Arlington",
    "University of Texas at Austin",
    "University of Texas at EL Paso",
    "University of Texas at San Antonio",
    "University of The Pacific",
    "University of Toledo",
    "University of Utah",
    "University of Virginia",
    "University of Washington",
    "University of Wisconsin Madison",
    "University of Wisconsin-Milwaukee",
    "University Texas- Dallas",
    "University Texas Health Science Centeral San Antonio",
    "Vanderbilt University",
    "Virginia Commonwealth University",
    "Virginia Polytechnic Institute and State University",
    "Wake Forest University",
    "Washington State University",
    "Washington University in St.Louis",
    "Wayne State University",
    "West Virginia University",
    "Yale University",
    "Yeshiva University"
];

export const Vietnam = [
    'Duy Tan University'
]